/*文章内容图片样式*/
.article-body img {
    max-width: 100%; /* 图片最大宽度为容器的100% */
    height: auto; /* 自动调整高度，保持原始比例 */
    border-radius: 4px;
}

/*文章内容文本样式*/
.article-content  {
    color: var(--color-text-2);
}

/*文章内容链接样式*/
.article-content a {
    text-decoration: none; /* 去掉下划线 */
    color: var(--color-text-1); /* 设置链接文字颜色 */
    font-weight: 500;
    /* 如果需要添加其它样式，比如鼠标悬停效果，可以使用如下方式 */
}

/*文章列表卡片自定义样式*/
.card-custom-hover-style {
    transition-property: all;
}

.card-custom-hover-style:hover {
    transform: translateY(-4px);
    box-shadow: 0 4px 10px rgb(var(--gray-3)); /*可能要修改*/
}

.card-custom-hover-style img {
    transition: opacity 0.15s ease-in-out; /* 添加过渡效果 */
}

.card-custom-hover-style:hover img {
    opacity: 0.90; /* 设置透明度为0.90 */
}


.card-custom-selected-style {
    background-color: var(--color-fill-2);
    border: 1px solid var(--color-border-2);
}

@media screen and (max-width: 700px) {
    .entry-col {
       display: none !important;
    }
}