@media screen and (max-width: 700px) {
    .sidebar {
        display: none !important;
    }
}

@media screen and (max-width: 700px) {
    .article-list {
        padding-left: 0 !important;
    }
}